import {
    Card,
    DotLoading,
    Empty,
    InfiniteScroll,
    Input,
    List,
    NavBar,
    SearchBar,
} from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Portal } from "react-portal";
import axiosInstance from "./common/api";

export const SelectUserPage = forwardRef(({ value, onChange }, ref) => {
    const [users, setUsers] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [params, setParams] = useState({});
    const [visible, setVisible] = useState(false);

    const fetch = () =>
        axiosInstance.get("user", { params }).then(({ data }) => {
            setUsers([...users,...data.results]);
            setHasMore(data.pagination.more);
            setParams({ ...params, page: (params.page ?? 1) + 1 });
        });

    useEffect(() => {
        if (visible) {
            setParams({});
            setUsers([]);
            setHasMore(true);
        }
    }, [visible]);

    useImperativeHandle(ref, () => ({
        open: () => setVisible(!visible),
        close: () => setVisible(false),
    }));

    return (
        <div>
            {users.filter((x) => x.id === value)[0]?.text ?? "Nessun dipendente selezionato"}
            {visible && (
                <Portal>
                    <div
                        onClick={(evt) => evt.stopPropagation()}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            minWidth: "100%",
                            minHeight: "100%",
                            zIndex: 999,
                            display: "flex",
                            flexDirection: "column",
                            background: "white",
                        }}
                    >
                        <Card>
                            <NavBar
                                backArrow={null}
                                children="Seleziona utente"
                                right={
                                    <CloseOutline
                                        fontSize={25}
                                        onClick={() => setVisible(false)}
                                        style={{ cursor: "pointer" }}
                                    />
                                }
                            />
                            <SearchBar
                                placeholder="Cerca"
                                onSearch={(text) => {
                                    setParams({ search: text });
                                    setUsers([]);
                                    setHasMore(true);
                                }}
                            />
                            <List>
                                {users.map((x) => (
                                    <List.Item
                                        arrow={false}
                                        onClick={() => {
                                            onChange?.(x.id);
                                            setVisible(false);
                                        }}
                                        key={x.id}
                                    >
                                        {x.text}
                                    </List.Item>
                                ))}
                            </List>
                            {users.length === 0 && !hasMore && (
                                <Empty description="Nessun utente" />
                            )}
                            <InfiniteScroll loadMore={fetch} hasMore={hasMore}>
                                {hasMore ? (
                                    <>
                                        <span>Caricamento</span>
                                        <DotLoading />
                                    </>
                                ) : (
                                    ""
                                )}
                            </InfiniteScroll>
                        </Card>
                    </div>
                </Portal>
            )}
        </div>
    );
});
