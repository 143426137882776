import { Button, DatePicker, Form, NavBar, TextArea } from "antd-mobile";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import axiosInstance from "./common/api";
import { SelectUserPage } from "./SelectUserPage";
import dayjs from "dayjs";
import { SignaturePage } from "./SignaturePage";
import Spinner from "./Spinner";

export default function CheckoutPage() {
    const { tag } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get(`hardware/${tag}`).then(({ data }) => {
            if (data.status === "error") alert(data.messages);
            else setData(data);
        });
    }, []);

    return (
        <>
            <NavBar onBack={() => navigate(-1)}>Assegnamento bene {tag}</NavBar>
            <Spinner spinning={loading}>
                <Form
                    layout="vertical"
                    onFinish={(form) => {
                        setLoading(true);
                        axiosInstance
                            .post(`hardware/${data.id}/checkout`, {
                                ...form,
                                checkout_to_type: "user",
                                expected_checkin: form.expected_checkin
                                    ? dayjs(form.expected_checkin).format("YYYY-MM-DD")
                                    : null,
                            })
                            .then(() => navigate(-1))
                            .finally(() => setLoading(false));
                    }}
                    footer={
                        <Button block type="submit" color="primary" size="large">
                            Conferma
                        </Button>
                    }
                >
                    <Form.Item
                        name={"assigned_user"}
                        label="Dipendente"
                        rules={[{ required: true, message: "Dipendente campo obbligatorio" }]}
                        onClick={(e, ref) => ref.current?.open()}
                    >
                        <SelectUserPage />
                    </Form.Item>
                    <Form.Item
                        name={"expected_checkin"}
                        label="Data di rientro attesa"
                        trigger="onConfirm"
                        onClick={(e, ref) => ref.current?.open()}
                    >
                        <DatePicker>
                            {(value) =>
                                value
                                    ? dayjs(value).format("DD/MM/YYYY")
                                    : "Nessuna data specificata"
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item name={"note"} label="Note">
                        <TextArea placeholder="Eventuali note" />
                    </Form.Item>
                    <Form.Item
                        name={"signature"}
                        label="Firma"
                        rules={[{ required: true, message: "Firma obbligatoria" }]}
                        onClick={(e, ref) => ref.current?.open()}
                    >
                        <SignaturePage />
                    </Form.Item>
                </Form>
            </Spinner>
        </>
    );
}
