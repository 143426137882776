import { Button, NavBar, Result, Space, SpinLoading, Tag } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "./common/api";
import DescriptionItem from "./DescriptionItem";
import Spinner from "./Spinner";

const tagFn = (status) => {
    switch (status) {
        case "deployable":
            return <Tag color="success">Assegnabile</Tag>;
        case "deployed":
            return <Tag color="primary">Assegnato</Tag>;
        case "undeployable":
            return <Tag color="danger">Non assegnabile</Tag>;
        default:
            return "";
    }
};

export default function HardwarePage() {
    const { tag } = useParams();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get(`hardware/${tag}`).then(({ data }) => {
            if (data.status === "error") setError(data.messages);
            else setData(data);
        });
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <NavBar onBack={() => navigate(-1)}>Bene {tag}</NavBar>
            <Spinner spinning={!(data.id || error)}>
                <>
                    {error && <Result status="error" title="Errore" description={error} />}
                    {data.id && (
                        <div style={{ display: "flex", flexDirection: "column", rowGap: 12 }}>
                            <DescriptionItem label="Modello" value={data.model?.name} />
                            <DescriptionItem label="Categoria" value={data.category?.name} />
                            <DescriptionItem
                                label="Seriale/Matricola"
                                visible={!!data.serial}
                                value={data.serial}
                            />
                            <DescriptionItem
                                label="Stato"
                                value={tagFn(
                                    data.status_label?.status_meta ??
                                        data.status_label?.status_type,
                                )}
                            />
                            <DescriptionItem
                                label="Assegnato a"
                                visible={!!data.assigned_to}
                                value={`${data.assigned_to?.name} il ${data.last_checkout?.formatted}`}
                            />
                            <DescriptionItem
                                label="Data di rientro attesa"
                                visible={!!data.expected_checkin}
                                value={data.expected_checkin?.formatted}
                            />
                            {!data.assigned_to && data.user_can_checkout && (
                                <Button
                                    block
                                    color="success"
                                    size="large"
                                    onClick={() => navigate(`/hw/${tag}/checkout`)}
                                >
                                    Assegna
                                </Button>
                            )}
                            {data.assigned_to && (
                                <Button
                                    block
                                    color="danger"
                                    size="large"
                                    onClick={() => navigate(`/hw/${tag}/checkin`)}
                                >
                                    Restituzione
                                </Button>
                            )}
                        </div>
                    )}
                </>
            </Spinner>
        </div>
    );
}
