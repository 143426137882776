import { Divider } from "antd-mobile";

export default function DescriptionItem({ visible, label, value }) {
    return visible !== false ? (
        <>
            <div style={{ lineHeight: 1.5, borderBottom: 'solid 1px #eeeeee', marginBottom: 5, paddingBottom: 20 }}>
                <span style={{ color: "rgb(102, 102, 102)" }}>{label}</span>
                <br />
                {value}
            </div>
        </>
    ) : (
        ""
    );
}
