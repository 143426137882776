import { SpinLoading } from "antd-mobile";

export default function Spinner({ spinning, children }) {
    return (
        <div style={{ position: "relative" }}>
            {spinning && (
                <div
                    onClick={evt => evt.stopPropagation()}
                    style={{
                        zIndex: 9999,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        background: "rgba(60, 60, 60, 0.2)",
                        justifyContent: "center",
                        alignItems: 'center'
                    }}
                >
                    <SpinLoading />
                </div>
            )}
            {children}
        </div>
    );
}
