import { Button, Form, NavBar, TextArea } from "antd-mobile";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import axiosInstance from "./common/api";
import Spinner from "./Spinner";

export default function CheckinPage() {
    const { tag } = useParams();
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get(`hardware/${tag}`).then(({ data }) => {
            if (data.status === "error") alert(data.messages);
            else setData(data);
        });
    }, []);

    return (
        <>
            <NavBar onBack={() => navigate(-1)}>Restituzione bene {tag}</NavBar>
            <Spinner spinning={loading}>
                <Form
                    layout="vertical"
                    onFinish={(form) => {
                        setLoading(true);
                        axiosInstance
                            .post(`hardware/${data.id}/checkin`, { ...form })
                            .then(() => navigate(-1))
                            .finally(() => setLoading(false));
                    }}
                    footer={
                        <Button block type="submit" color="primary" size="large">
                            Conferma
                        </Button>
                    }
                >
                    <Form.Item name={"note"} label="Note">
                        <TextArea placeholder="Eventuali note" />
                    </Form.Item>
                </Form>
            </Spinner>
        </>
    );
}
