import { AutoCenter, Button, Card, SearchBar, Space } from "antd-mobile";
import { SystemQRcodeOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router";

export default function SearchHardwarePage() {
    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
            <h2>Ricerca beni</h2>
            <SearchBar
                onSearch={(value) => navigate(`hw/${value}`)}
                placeholder="Inserisci etichetta manualmente"
            />
            <div>Oppure</div>
            <Button block color="primary" onClick={() => navigate("scan")}>
                <SystemQRcodeOutline /> Scansiona
            </Button>
        </div>
    );
}
