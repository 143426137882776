import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CheckLogin from "./CheckLogin";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd-mobile";
import itIT from "antd-mobile/es/locales/it-IT";
import HardwarePage from "./HardwarePage";
import SearchHardwarePage from "./SearchHardwarePage";
import CheckinPage from "./CheckinPage";
import CheckoutPage from "./CheckoutPage";
import ScanPage from "./ScanPage";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from  'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                path: "/",
                element: <SearchHardwarePage />,
            },
            {
                path: 'scan',
                element: <ScanPage/>
            },
            {
                path: "hw/:tag",
                element: <HardwarePage />,
            },
            {
                path: "hw/:tag/checkin",
                element: <CheckinPage/>
            },
            {
                path: "hw/:tag/checkout",
                element: <CheckoutPage/>
            }
        ],
    },
]);
root.render(
    <ConfigProvider locale={itIT}>
        <CheckLogin>
            <RouterProvider router={router} />
        </CheckLogin>
    </ConfigProvider>,
);
