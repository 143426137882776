import { Outlet } from "react-router";
import SelectUserPage from "./SelectUserPage";

function App() {
    return (
        <div style={{padding: 16}}>
            <Outlet />
        </div>
    );
}

export default App;
