import { Scanner } from "@yudiel/react-qr-scanner";
import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router";

export default function ScanPage() {
    const navigate = useNavigate();
    const regexp = /.+\/(.+)/g
    return (
        <>
            <NavBar onBack={() => navigate(-1)}>Scansione etichetta</NavBar>
            <div style={{marginTop: 30}}>
                <Scanner
                    onScan={([value]) => navigate(`/hw/${regexp.exec(value.rawValue)[1]}`)}
                    components={{ finder: false }}
                />
            </div>
        </>
    );
}
