
import { SpinLoading } from "antd-mobile";
import {useEffect, useState} from "react";
import { keycloack } from "./common/keycloak";


export default function CheckLogin(props) {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        keycloack()
            .then(() => setAuthenticated(true))
    }, []);

    return authenticated ? props.children : <SpinLoading spinning={true}/>
}
