import { Button, NavBar, Popup } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Portal } from "react-portal";
import MySignaturePad from "./MySignaturePad";

export const SignaturePage = React.forwardRef(({ value, onChange }, ref) => {
    const [visible, setVisible] = useState(false);
    const signRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: () => setVisible(!visible),
        close: () => setVisible(false),
    }));

    useEffect(() => {
        if (visible) signRef.current?.clear();
    }, [visible]);
    return (
        <>
            {<img src={value} height={100} />}
            <Popup
                position="bottom"
                visible={visible}
                bodyStyle={{ height: "80vh" }}
                onClose={() => {
                    setVisible(false);
                }}
            >
                <NavBar
                    backArrow={null}
                    children="Firma"
                    right={
                        <CloseOutline
                            fontSize={25}
                            onClick={() => {
                                setVisible(false);
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    }
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 30,
                        rowGap: 50,
                    }}
                >
                    <MySignaturePad width={400} height={200} ref={signRef} />
                    <Button
                        block
                        color="primary"
                        onClick={() => {
                            onChange?.(signRef.current.getValue());
                            setVisible(false);
                        }}
                    >
                        Conferma
                    </Button>
                </div>
            </Popup>
        </>
    );
});
