
import { Toast } from "antd-mobile";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, function (error) {

    if (error.response.status === 403) {
            Toast.show({
                icon: "error",
              content: 'Permessi insufficienti',
            })
    } else {
            Toast.show({
                content: "Errore: " + (error.response?.data?.message ?? "axios.server.error"),
                icon: "success"
            })
    }
    return Promise.reject(error)
});


export default axiosInstance
